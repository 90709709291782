// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contatos-js": () => import("./../../../src/pages/contatos.js" /* webpackChunkName: "component---src-pages-contatos-js" */),
  "component---src-pages-empresa-js": () => import("./../../../src/pages/empresa.js" /* webpackChunkName: "component---src-pages-empresa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-produtos-chaves-eletricas-index-js": () => import("./../../../src/pages/produtos/chaves-eletricas/index.js" /* webpackChunkName: "component---src-pages-produtos-chaves-eletricas-index-js" */),
  "component---src-pages-produtos-conectores-tomadas-index-js": () => import("./../../../src/pages/produtos/conectores-tomadas/index.js" /* webpackChunkName: "component---src-pages-produtos-conectores-tomadas-index-js" */),
  "component---src-pages-produtos-contatores-reles-index-js": () => import("./../../../src/pages/produtos/contatores-reles/index.js" /* webpackChunkName: "component---src-pages-produtos-contatores-reles-index-js" */),
  "component---src-pages-produtos-diversos-index-js": () => import("./../../../src/pages/produtos/diversos/index.js" /* webpackChunkName: "component---src-pages-produtos-diversos-index-js" */),
  "component---src-pages-representantes-index-js": () => import("./../../../src/pages/representantes/index.js" /* webpackChunkName: "component---src-pages-representantes-index-js" */),
  "component---src-pages-servicos-js": () => import("./../../../src/pages/servicos.js" /* webpackChunkName: "component---src-pages-servicos-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

