module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K25NDZS","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/repo-git/ncmgdev/eletrosil/src/translations","languages":["pt","en","es"],"defaultLanguage":"pt","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Eletrosil Indústria Metalúrgica Ltda.","short_name":"Eletrosil","lang":"pt-br","start_url":"/","background_color":"#FFFFFF","theme_color":"#0065B2","display":"minimal-ui","icon":"static/assets/img/favicon.ico","icon_options":{"purpose":"any maskable"},"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"2d68cf010920b7fc97787b9ab0abd326"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/styles/typography.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"eletrosil","accessToken":"MC5YMFZ3OGhJQUFLTmJRdkZu.77-977-9I--_vVrvv73vv73vv70j77-977-977-9XXvvv73vv73vv73vv71aF1jvv70K77-9c0rvv73vv73vv73vv73vv717","schemas":{"contacts":{"Main":{"andress":{"type":"StructuredText","config":{"multi":"paragraph","allowTargetBlank":true,"label":"andress","placeholder":"Endereço"}},"region":{"type":"StructuredText","config":{"multi":"paragraph","allowTargetBlank":true,"label":"region","placeholder":"Bairro"}},"city":{"type":"StructuredText","config":{"multi":"paragraph","allowTargetBlank":true,"label":"city","placeholder":"Cidade"}},"state":{"type":"StructuredText","config":{"multi":"paragraph","allowTargetBlank":true,"label":"state","placeholder":"Estado"}},"code_postal":{"type":"StructuredText","config":{"multi":"paragraph","allowTargetBlank":true,"label":"code_postal","placeholder":"CEP"}}},"Phones":{"pabx1":{"type":"StructuredText","config":{"multi":"paragraph","allowTargetBlank":true,"label":"pabx1","placeholder":"Telefone Fixo 1"}}},"Emails":{"email_vendas":{"type":"StructuredText","config":{"multi":"paragraph","allowTargetBlank":true,"label":"email_vendas","placeholder":"E-mail Setor Comercial"}},"email_tecnico":{"type":"StructuredText","config":{"multi":"paragraph","allowTargetBlank":true,"label":"email_tecnico","placeholder":"E-mail Setor Técnico"}},"email_qualidade":{"type":"StructuredText","config":{"multi":"paragraph","label":"email_qualidade","placeholder":"E-mail Setor de Qualidade"}},"email_financeiro":{"type":"StructuredText","config":{"multi":"paragraph","allowTargetBlank":true,"label":"email_financeiro","placeholder":"E-mail Setor Financeiro"}},"email_rh":{"type":"StructuredText","config":{"multi":"paragraph","allowTargetBlank":true,"label":"email_rh","placeholder":"E-mail Setor Recursos Humanos"}},"email_compras":{"type":"StructuredText","config":{"multi":"paragraph","label":"email_compras","placeholder":"E-mail Setor de Compras"}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
